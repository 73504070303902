import React, { Component } from "react";
import { Container, Form } from "react-bootstrap";
import CharacterForm1 from "./CharacterForm1";
import CharacterForm2 from "./CharacterForm2";
import Axios from "axios";
import "./css/CreateCharacter.css";
import { Navigate } from "react-router-dom";

import { CHARACTER_DEFAULTS } from "../data/character";
import "./css/CreateCharacter.css";

const { Configuration, OpenAIApi } = require("openai");

const CLASSES = CHARACTER_DEFAULTS.class.map((v) => {
  return v.toLowerCase();
});

const RANDOM_CLASSES = CHARACTER_DEFAULTS.random.map((v) => {
  return v.toLowerCase();
});
export default class CreateCharacter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      heading: "Backstory",
      generateRandomCharacter: false,
      isLoading: true,
      user: null,
      newCharacter: {
        name: "",
        class: "",
        ability: "",
        weakness: "",
        backstory: "",
        tone: "dark",
        user: "",
        image: "/images/class/default.png",
      },
      tokenShow: true,
      tokenCount: null,
      character: "",
      log: [],
      prompt: "",
      name: "",
      redirect: false,
      placeholder: {
        name: "Gandalf",
        class: "Wizard",
        ability: "Speaking in riddles",
        weakness: "Hobbits",
        tone: "dark",
        backstory: 'Please chose a tone and click "Generate Backstory" above.',
      },
    };

    // this.handleChange = this.handleChange.bind(this);
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
  }

  async componentDidMount() {
    const { user } = this.props;
    if (user) {
      this.setState({
        isLoading: false,
        user: user.id,
        newCharacter: {
          ...this.state.newCharacter,
          user: user.id,
        },
      });
    }
    if (this.props.randomCharacter) {
      this.createRandomCharacter();
    }

    Axios.get(`/profile/${user.id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }).then((response) => {
      // console.log("tokens", response.data.user.tokens);
      this.setState({
        tokenCount: response.data.user.tokens,
        tokenShow: response.data.user.tokens < 50000,
      });
    });
    // console.log("id", this.props.user.id);
  }

  createRandomCharacter = () => {
    //randomly generate an impressive name!
    let name = "";
    if (Math.floor(Math.random() * 2) < 1) {
      //50% chance female or male (sorry we haven't got to non-binary yet!!)
      if (Math.floor(Math.random() * 2) < 1) {
        //50% chance of a pronoun/title
        name +=
          CHARACTER_DEFAULTS.title_f[
            Math.floor(Math.random() * CHARACTER_DEFAULTS.title_f.length)
          ] + " ";
      }
      name +=
        CHARACTER_DEFAULTS.name_f[
          Math.floor(Math.random() * CHARACTER_DEFAULTS.name_f.length)
        ];
    } else {
      //male names
      if (Math.floor(Math.random() * 2) < 1) {
        //50% chance of a pronoun/title
        name +=
          CHARACTER_DEFAULTS.title_m[
            Math.floor(Math.random() * CHARACTER_DEFAULTS.title_m.length)
          ] + " ";
      }
      name +=
        CHARACTER_DEFAULTS.name_m[
          Math.floor(Math.random() * CHARACTER_DEFAULTS.name_m.length)
        ];
    }
    if (Math.floor(Math.random() * 2) < 1) {
      //50% chance of a decorative post-title
      name +=
        " " +
        CHARACTER_DEFAULTS.decor[
          Math.floor(Math.random() * CHARACTER_DEFAULTS.decor.length)
        ];
    }
    //ability and weakness cannot be the same thing
    let ability =
      CHARACTER_DEFAULTS.trait[
        Math.floor(Math.random() * CHARACTER_DEFAULTS.trait.length)
      ];
    let weakness = CHARACTER_DEFAULTS.trait.filter((t) => {
      return t !== ability;
    })[Math.floor(Math.random() * CHARACTER_DEFAULTS.trait.length - 1)];

    const character = {
      name: name,
      class:
        CHARACTER_DEFAULTS.class[
          Math.floor(Math.random() * CHARACTER_DEFAULTS.class.length)
        ],
      ability: ability,
      weakness: weakness,
      backstory: "", // default
      tone: "dark", // default
      user: this.props.user.id,
    };
    this.setState({
      placeholder: character,
      newCharacter: character,
      generateRandomCharacter: this.props.randomCharacter,
    });
  };

  handleChange = (event) => {
    const attributeToChange = event.target.name; // this will give the name of the field that is changing
    const newValue = event.target.value; //this will give the value of the field that is changing

    const character = { ...this.state.newCharacter };
    character[attributeToChange] = newValue;
    // console.log("onchange", character);
    this.setState({
      newCharacter: character,
      generateRandomCharacter: false,
    });
  };

  addCharacter = (character) => {
    if (
      this.state.newCharacter.name.toLowerCase().includes("saad iqbal") ||
      this.state.newCharacter.name.toLowerCase().includes("saad")
    ) {
      console.log("saad detected");
      character.image = "/images/class/saad.png";
    }
    if (
      this.state.newCharacter.name.toLowerCase().includes("martin") ||
      this.state.newCharacter.name.toLowerCase().includes("marty")
    ) {
      console.log("marty detected");
      character.image = "/images/class/marty.png";
    }
    // console.log("add char test", character);
    Axios.post("character/add", character, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => {
        // console.log("character success", character);
        if (response.data.error) {
          console.log("Error adding character.", response.data.error);
          this.props.setMessage(
            response.data.error._message +
              ". Please confirm you have correctly filled out all the fields in the character creation form.\nIf the issue persists please contact the developers and quote: Character/" +
              response.data.error.name,
            "danger"
          );
        } else {
          // console.log("Character added successfully.", response);
          this.props.createAdventure(this.state.newCharacter);
          this.setState({
            redirect: true,
          });
        }
        // this.loadCharacterList();
      })
      .catch((error) => {
        // console.log("character attempt", character);
        console.log("Error adding character.", error);
        this.props.setMessage(error.message, "danger");
      });
  };

  generateBackstory = (e) => {
    // console.log("CREATING BACKSTORY");
    e.preventDefault();
    // console.log(e.target.parent);
    // const formData = new FormData(e.target.parentNode),
    // formDataObj = Object.fromEntries(formData.entries());
    // console.log(this.state.newCharacter);
    const formDataObj = this.state.newCharacter;
    // console.log(formDataObj)
    let backstoryInfo = {
      backstory: `Generating a ${this.state.newCharacter.tone.toLowerCase()} backstory for ${
        formDataObj.name
      }. Please wait...`,
    };

    this.setState({
      placeholder: backstoryInfo,
      // newCharacter: {backstory: ""},
    });

    let AIprompt = `${formDataObj.name} is a ${
      formDataObj.class
    } who has the power of ${formDataObj.ability.toLowerCase()} and a weakness to ${formDataObj.weakness.toLowerCase()}. Write a detailed and ${formDataObj.tone.toLowerCase()} back story about ${
      formDataObj.name
    } in 100 words.\n`;
    this.setState({ prompt: AIprompt });
    ////Open Ai Goes here

    const configuration = new Configuration({
      apiKey: process.env.REACT_APP_API_KEY,
    });
    const openai = new OpenAIApi(configuration);
    openai
      .createCompletion(process.env.REACT_APP_API_ENGINE, {
        prompt: AIprompt,
        temperature: 0.8,
        max_tokens: 1000,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      })
      .then((response) => {
        this.props.addTokens(response.data.usage.total_tokens);
        let backstory = response.data.choices[0].text;
        if (backstory[0] === "\n") {
          backstory = backstory.slice(1, backstory.length);
        }
        const character = { ...this.state.newCharacter };
        character.backstory = backstory;
        character.user = this.state.user;
        this.setState({
          heading: `Backstory for: ${formDataObj.name}`,
          placeholder: {
            backstory:
              'Please chose a tone and click "Generate Backstory" above.',
          },
          newCharacter: character,
          log: [...this.state.log, AIprompt, response.data.choices[0].text],
          name: formDataObj.name,
        });
      })
      .catch((error) => {
        console.log("error log:", error);
        this.props.setMessage(
          error.message +
            ". If the issue persists, please contact the developers.",
          "danger"
        );
      });

    //Use a timeout/clock here to randomly change state.response to keep things interesting while the AI thinks?
  };

  setImage() {
    let path = "";
    if (CLASSES.indexOf(this.state.newCharacter.class.toLowerCase()) !== -1) {
      path = `/images/class/${this.state.newCharacter.class
        .toLowerCase()
        .replace(" ", "")}.png`;
    } else {
      const imgList = RANDOM_CLASSES.concat(CLASSES);
      const randImg = imgList[Math.floor(Math.random() * imgList.length)];
      path = `/images/class/${randImg.replace(" ", "")}.png`;
    }
    this.setState({
      newCharacter: {
        ...this.state.newCharacter,
        image: path,
      },
    });
  }

  achievementCheck(character) {
    if (
      this.state.newCharacter.name.includes("Saad Iqbal") ||
      this.state.newCharacter.name.includes("Saad")
    ) {
      // console.log("saad detected");
      this.props.createAchievement(8);
    }
    if (
      this.state.newCharacter.name.includes("Martin") ||
      this.state.newCharacter.name.includes("Marty")
    ) {
      // console.log("marty detected");
      this.props.createAchievement(9);
    }
  }

  onFormSubmit = (e) => {
    // this.props.charCreateAchievement();
    this.props.createAchievement(1);
    this.achievementCheck();

    e.preventDefault();

    this.setImage();

    // console.log("user: ", this.state.newCharacter.user);

    setTimeout(() => {
      this.addCharacter(this.state.newCharacter);
    }, 100);
    // this.achievementCheck(this.state.newCharacter.name);
  };

  _next() {
    let currentStep = this.state.currentStep;
    currentStep = currentStep < 2 ? currentStep + 1 : currentStep;
    this.setState({
      currentStep: currentStep,
      placeholder: {
        backstory: 'Please chose a tone and click "Generate Backstory" above.',
      },
    });
  }

  _prev() {
    let currentStep = this.state.currentStep;
    currentStep = currentStep > 1 ? currentStep - 1 : currentStep;
    this.setState({
      currentStep: currentStep,
    });
  }

  get previousButton() {
    let currentStep = this.state.currentStep;
    if (currentStep !== 1) {
      return (
        <button
          className='btn btn-secondary'
          type='button'
          onClick={this._prev}
        >
          Previous
        </button>
      );
    }
    return null;
  }

  get nextButton() {
    let currentStep = this.state.currentStep;
    if (currentStep < 2) {
      return (
        <button
          className='btn btn-secondary'
          type='button'
          onClick={this._next}
        >
          Next
        </button>
      );
    }
    return null;
  }

  get randomButton() {
    let currentStep = this.state.currentStep;
    if (currentStep < 2) {
      return (
        <button
          className='btn btn-dark'
          type='button'
          onClick={this.createRandomCharacter}
        >
          Randomize Character
        </button>
      );
    }
    return null;
  }

  render() {
    const { isLoading, user, newCharacter, tokenShow } = this.state;
    // console.log("user: ", this.props.user.tokens);

    if (!tokenShow) {
      return (
        <div className='relative w-full h-full overflow-hidden bg-cover bg-50 bg-no-repeat'>
          <div className='items-center w-100'>
            <img
              src='https://i.imgur.com/zAzseke.jpg'
              className='outImage'
            ></img>
          </div>
          <div className='absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-auto bg-fixed bg-grey'>
            <div className='flex h-full  my-5 flex-col max-w-80 mx-auto text-left outOfTokens'>
              <h2 className='textwhite opacity-100'>
                Thank you for using AI Adventure!
              </h2>
              <p>&nbsp;</p>
              <p className='textwhite opacity-100'>
                {" "}
                {`We hope you enjoyed the app and were able to go on a number of entertaining adventures`}{" "}
                {`We are thrilled that the app has been more popular than expected.`}
              </p>
              <p>&nbsp;</p>
              <p className='textwhite opacity-100'>
                {" "}
                {` Unfortunately, the costs of maintaining AI Adventure are adding up and
              it looks like you've used up all your free tokens.`}{" "}
              </p>
              <p>&nbsp;</p>
              <p className='textwhite opacity-100'>
                {`Don't worry though - you can`}
                <a href='https://dylankotzer.com/' className='text-blue-400'>
                  {" "}
                  contact Dylan Kotzer
                </a>{" "}
                {` to request more tokens or let us know about any features you'd
              like to see, bugs you've encountered, or feedback you have. We're
              always looking for ways to improve Adventure AI and make it even
              better for our users.`}
              </p>
              <p>&nbsp;</p>
              <p className='textwhite opacity-100'>
                {`Thanks again for Adventuring with us, and we appreciate your
              understanding as we work to keep the app sustainable. We hope to
              hear from you soon!`}
              </p>
            </div>
          </div>
        </div>
      );
    }

    // console.log(this.props)
    return !isLoading ? (
      <>
        <Container className='container-fluid my-5'>
          <h1 className='display-4'>Create a Character</h1>

          <Form onSubmit={this.onFormSubmit} className='form-container'>
            <CharacterForm1
              currentStep={this.state.currentStep}
              placeholder={this.state.placeholder}
              generateRandomCharacter={this.state.generateRandomCharacter}
              newCharacter={this.state.newCharacter}
              handleChange={this.handleChange}
            />
            <CharacterForm2
              currentStep={this.state.currentStep}
              placeholder={this.state.placeholder}
              generateRandomCharacter={this.state.generateRandomCharacter}
              newCharacter={this.state.newCharacter}
              handleChange={this.handleChange}
              log={this.state.log}
              generateBackstory={this.generateBackstory}
              isBackstory={this.state.newCharacter.backstory.length > 0}
            />
            {this.previousButton}
            {this.nextButton}
            &nbsp; &nbsp;
            {this.randomButton}
          </Form>
        </Container>
        {this.state.redirect && (
          <Navigate
            to='/create-adventure'
            replace={true}
            character={this.state.newCharacter}
            achievement={true}
            setMessage={this.props.setMessage}
            user={this.props.user}
          />
        )}
      </>
    ) : (
      <div>
        <br />
        <br />
        <br />
        <br />
        <h2 className='center'>Refresh error, please go back and try again</h2>
      </div>
    );
  }
}

const TITLE_F = [
  "Miss",
  "Mrs",
  "Ms",
  "Mx",
  "Lady",
  "Duchess",
  "Queen",
  "Dame",
  "Princess",
  "Mother",
  "Sister",
  "Baroness",
  "Madam",
  "Senator",
  "President",
  "Prime Minister",
  "Honorable",
  "Justice",
  "Archduchess",
  "Countess",
  "Viscountess",
  "Tsarina",
  "Empress",
  "Magistrate",
  "Councillor",
  "Abbess",
  "Dr",
  "Professor",
  "Captain",
  "Commander",
  "Major",
  "General",
  "Colonel",
  "Admiral",
  "Officer",
  "Sheriff",
  "Private",
  "Sergeant",
  "Lieutenant",
  "Constable",
  "Agent",
  "Coach",
  "Comrade",
  "Sultana",
  "The Great",
];

const TITLE_M = [
  "Mr",
  "Mx",
  "Master",
  "Sir",
  "Lord",
  "Duke",
  "Esquire",
  "Knight",
  "King",
  "Prince",
  "Father",
  "Brother",
  "Baron",
  "Earl",
  "Senator",
  "President",
  "Prime Minister",
  "Honorable",
  "Justice",
  "Archduke",
  "Count",
  "Viscount",
  "Chieftan",
  "Tsar",
  "Emperor",
  "Pope",
  "Bishop",
  "Archbishop",
  "Magistrate",
  "Councillor",
  "Abbot",
  "Friar",
  "Saint",
  "Dr",
  "Professor",
  "Captain",
  "Commander",
  "Major",
  "General",
  "Colonel",
  "Admiral",
  "Officer",
  "Sheriff",
  "Private",
  "Sergeant",
  "Lieutenant",
  "Constable",
  "Agent",
  "Coach",
  "Comrade",
  "Sultan",
  "The High King",
  "The Great",
];

const NAME_F = [
  "Sam",
  "Elizabeth",
  "Cleopatra",
  "Mary",
  "Margaret",
  "Sally",
  "Sophie",
  "Daisy",
  "Dora",
  "Alex",
  "Lila",
  "Nicole",
  "Janet",
  "Meghan",
  "Rebecca",
  "Kate",
  "Katie",
  "Alexandra",
  "Joanna",
  "Joan",
  "Zoe",
  "Hillary",
  "Tiffany",
  "Hannah",
  "Brittany",
  "Lauren",
  "Taylor",
  "Stephanie",
  "Charlotte",
  "Abby",
  "Amy",
  "Sarah",
  "Olivia",
  "Emma",
  "Sophia",
  "Isabella",
  "Mia",
  "Tasmin",
  "Amelia",
  "Evelyn",
  "Abigail",
  "Ella",
  "Sofia",
  "Elanor",
  "Lydia",
  "Grace",
  "Chloe",
  "Victoria",
  "Lily",
  "Natalie",
  "Claire",
  "Caroline",
  "Bella",
  "Alice",
  "Hailey",
  "Clara",
  "Rose",
  "Julia",
  "Kylie",
  "Kim",
  "Jasmine",
  "Daisy",
  "Andrea",
  "Esther",
  "Ceclia",
  "Anatasia",
  "Molly",
  "Holly",
  "Kimberly",
  "Genevieve",
  "Freya",
  "Jordyn",
  "Jordan",
  "Olive",
  "Vanessa",
  "Elise",
  "Vera",
  "Jocelyn",
  "Jane",
  "Paige",
  "Adelaide",
  "Tessa",
  "Annie",
  "Angelina",
  "Fiona",
  "Juliet",
  "Nina",
  "Melissa",
  "Laura",
];

const NAME_M = [
  "Sam",
  "John",
  "Saad",
  "Leeroy",
  "Michael",
  "Jack",
  "Isaac",
  "Alex",
  "Marty",
  "Martin",
  "Brandon",
  "Derek",
  "Muhammad",
  "Jose",
  "Andrew",
  "Basheer",
  "Charles",
  "George",
  "Harry",
  "William",
  "Stanley",
  "Jibran",
  "Matthew",
  "Ali",
  "Philip",
  "Jon",
  "Jonathan",
  "Mahmoud",
  "Mateas",
  "Mohammad",
  "Nate",
  "Rui",
  "Salman",
  "Dylan",
  "Henry",
  "Duncan",
  "Joe",
  "Donald",
  "Bob",
  "Robert",
  "Erol",
  "Aiden",
  "Doug",
  "Chris",
  "Daniel",
  "Austin",
  "Justin",
  "Jacob",
  "Joseph",
  "Marco",
  "Leonardo",
  "Adam",
  "Anthony",
  "Cedric",
  "Alwin",
  "Frank",
  "Bobby",
  "Jim",
  "James",
  "Jordan",
  "Hans",
  "Romeo",
  "Colin",
  "Noah",
  "Ethan",
  "Benjamin",
  "Lucas",
  "Elijah",
  "Liam",
  "Daniel",
  "Sebastian",
  "Theodore",
  "David",
  "Julian",
  "Thomas",
  "Ezra",
  "Anthony",
  "Joshua",
  "Ryan",
  "Adrian",
  "Boris",
  "Aaron",
  "Nolan",
  "Connor",
  "Ian",
  "Nicholas",
  "Xavier",
  "Alan",
  "Bryan",
  "Corey",
  "Damian",
  "Eric",
  "Gary",
  "Jared",
  "Jeremy",
  "Jason",
  "Kenneth",
  "Keith",
  "Mark",
  "Morgan",
  "Nelson",
  "Rhys",
  "Scott",
  "Elliot",
  "Howard",
  "Louis",
  "Paul",
  "Peter",
  "Quincy",
  "Roger",
  "Shaun",
  "Shane",
  "Simon",
  "Solomon",
  "Tobias",
  "Wallace",
  "Vincent",
  "Zachary",
];

const DECOR = [
  //Epithet/byname
  "Khan",
  "Worldeater",
  "of Arc",
  "of Many Colours",
  "of Many Worlds",
  "the Bald",
  "the Black",
  "the Bold",
  "the Brave",
  "the Brown",
  "the Builder",
  "the Conqueror",
  "the Cruel",
  "the Demented",
  "the Defiant",
  "the Dreadful",
  "the Dreamy",
  "the Drunk",
  "the Explorer",
  "the Fair",
  "the Fat",
  "the Fearless",
  "the Gentle",
  "the Gifted",
  "the Giant",
  "the Good",
  "the Grave",
  "the Greedy",
  "the Great",
  "the Hawk",
  "the Ironclad",
  "the Joyful",
  "the Just",
  "the Kingslayer",
  "the Kindhearted",
  "the Lion",
  "the Lionheart",
  "the Magnificent",
  "the Mad",
  "the Merciful",
  "the Navigator",
  "the Peaceful",
  "the Pious",
  "the Raven",
  "the Reaper",
  "the Red",
  "the Shapeshifter",
  "the Shepherd",
  "the Silent",
  "the Sly",
  "the Steelfist",
  "the Strong",
  "the Terrible",
  "the Twisted",
  "the Undying",
  "the Unfortunate",
  "the Unlucky",
  "the Unwise",
  "the Usurper",
  "the White",
  "the Wise",
  "the Worthy",
  "the Younger",
  "the Fifth"
];

const CLASS = ["Alchemist", "Archangel", "Archer", "Astronomer", "Assassin", "Bandit", "Bard", "Barbarian", "Beastmaster",
  "Berserker", "Bishop", "Blacksmith", "Brute", "Busker", "Cat", "Cleric", "Centaur", "Corgi", "Cultist",
  "Crusader", "Cybernetic", "Dog", "Dragon", "Dragonborn", "Druid", "Dwarf", "Engineer", "Ent",
  "Faun", "Gambler", "Ghost", "Giant", "Gladiator", "Gnome", "Goblin", "Gunslinger", "Gypsy", "Hacker", "Headhunter", "Horseman", "Hunter", "Illusionist", "Inquisitor", "Inventor",
  "Jester", "King", "Knight", "Leader", "Librarian", "Mage", "Marine", "Medic", "Merchant", "MMA Fighter", "Monk", "Mystic", "Necromancer", "Ogre", "Orator", "Orc", "Outlaw", "Paladin", "Phantom", "Pirate",
  "Monk", "Mystic", "Necromancer", "Ogre", "Orator", "Orc", "Outlaw", "Paladin", "Phantom", "Pirate",
  "Priest", "Programmer", "Psychic", "Ranger", "Rogue", "Robber", "Sapper", "Samurai", "Scholar", "Scout", "SEI Grad",
  "Shaman", "Sharpshooter", "Shapeshifter", "Skald", "Skirmisher", "Sniper", "Sorcerer", "Squire", "Spectre", "Spy",
  "Summoner", "Tailor", "Templar", "Thief", "Time Traveller", "Vampire", "Warlock", "Warrior", "Wanderer",
  "Werewolf", "Wizard", "Zealot", "Zookeeper"
];

const TRAIT = [
  "Allergies",
  "Animal Whispering",
  "Archaeology",
  "Astronomy",
  "Astrology",
  "Baking",
  "Biology",
  "Birdwatching",
  "Business",
  "Chemistry",
  "Cheating",
  "Clairvoyance",
  "Coding",
  "Cooking",
  "Crafting",
  "Critical thinking",
  "Dancing",
  "Debating",
  "Dodging difficult questions",
  "Drawing",
  "Drama",
  "Elasticity",
  "Empathy",
  "Empathy",
  "Flexibility",
  "Fishing",
  "Flying",
  "Forcefields",
  "Foresight",
  "Gaming",
  "Geography",
  "Gluttony",
  "Gymnastics",
  "Hacking",
  "Healing",
  "History",
  "Hindsight",
  "Immortality",
  "Inventing things",
  "Invisibility",
  "Invincibility",
  "Karate",
  "Kryptonite",
  "Laser vision",
  "Laser vision",
  "Lockpicking",
  "Love",
  "Magic",
  "Making stuff up",
  "Mathematics",
  "Mining",
  "Mind control",
  "Naivety",
  "Napping",
  "Neuroscience",
  "Omniscience",
  "Perception",
  "Persistence",
  "Photography",
  "Poetry",
  "Portals",
  "Problem solving",
  "Prompt Engineering",
  "Pro Gaming",
  "Procrastination",
  "Programming",
  "Puns",
  "Public speaking",
  "Puzzles",
  "Reading",
  "Recycling",
  "Red",
  "Relaxing",
  "Resurrection",
  "Robbing banks",
  "Run on sentences",
  "Running",
  "Sailing",
  "Samurai sword mastery",
  "Seduction",
  "Self-motivation",
  "Shapeshifting",
  "Singularity",
  "Singing",
  "Smooth talking",
  "Spiders",
  "Spreadsheets",
  "Stripping",
  "Stylish attire",
  "Supersonic speed",
  "Superhuman strength",
  "Surgery",
  "Sustainability",
  "Swimming",
  "Sympathy",
  "Telekinesis",
  "Telepathy",
  "Teleportation",
  "the Force",
  "the Internet",
  "Time manipulation",
  "Time travel",
  "Volunteering",
  "Walking on hot coals",
  "Water",
  "Werewolf",
  "Wiki master",
  "Wikipedia",
  "Witchcraft",
  "Writing",
  "Yoga",
];

let randomImages = CLASS.map(element => element.toLowerCase());

const RANDOM = randomImages;

export const CHARACTER_DEFAULTS = {
  title_f: TITLE_F,
  title_m: TITLE_M,
  name_f: NAME_F,
  name_m: NAME_M,
  decor: DECOR,
  class: CLASS,
  trait: TRAIT,
  random: RANDOM,
};

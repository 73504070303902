export const OPTION_DEFAULTS = [
    "Ask a close friend for help",
    "Take a long walk and think about the situation",
    "Try to discover the meaning of life",
    "Turn around and go back the way you came",
    "Hug the person nearest to you",
    "Visit the structure nearby",
    "Talk to an AI",
    "Talk to someone about your problem",
    "Write about your experience",
    "Complain on the internet",
    "Start breakdancing",
    "Sing a song",
    "Eat a chocolate bar while waiting for something to happen",
    "Leave a positive review and tell all your firends about it",
    "Escape to the city",
    "Confess your undying love",
    "Change the subject awkwardly",
    "Look for clues elsewhere",
    "Share on social media",
    "Run away",
    "Stand your ground",
    "Begin taunting your opponent",
    "Phone a friend",
    "Go 50/50",
    "Ask the audience",
    "Discuss with a passerby",
    "Open a shop",
    "Take a step back and just go home",
    "Think about what could go wrong",
    "Hit it with a shovel",
    "Go on a walking tour",
    "Take a walk on the beach",
    "Go for a hike in the woods",
    "Talk to your friends",
    "Watch a movie online",
    "Play a video game",
    "Deface something",
    "Pick it up and hold it to the sky",
    "Boil 'em, mash 'em, put 'em in a stew",
    "Thank the developers of this app",
    "Travel back in time and change things",
]